import { CopyOutlined, DeleteOutlined, DownloadOutlined, TagsOutlined } from '@ant-design/icons';
import { Modal, Popconfirm, Popover, Select, Space, Tooltip } from 'antd';
import { compose, equals, filter, isNil, map, path, pathEq, prop, assoc, propOr } from 'ramda';
import { isNotNil, notEqual, propNotEq } from 'ramda-adjunct';
import React, { useState } from 'react';
import { useTreeContext } from '../../../../components/treeStructure/treeContext';
import useDocumentsTable from '../../../../hooks/useDocumentsTable';
import SelectTags from '../../../docs/components/document/SelectTags';
import { updateKeys } from '../../../../components/treeStructure/treeHelper';
import { errorMessage, successMessage } from '../../../../utils/messageMutation';
import DuplicateDocumentAndItsWidgets from '../../../../_graphql/mutations/documents/DuplicateDocumentAndItsWidgets';
import { setElementClass } from '../../../helpers/setElementClass';
import { QOffers } from '../../../../_graphql/queries';
import GenerateStructuredHtmlMutation from '../../../../_graphql/mutations/documents/GenerateStructuredHtmlMutation';
import { downloadHtmlFile } from '../../../../utils/downloadHtmlFile';

const DocumentRights = ({
  doc,
  offerId,
  userIsAdmin,
  setDocuments,
  folder,
  refetch,
  setRefetchWidgets,
  isPublished,
  documentRights,
  documentName,
  teams,
  userTeam,
  setFullscreenLoading,
  setRefetchTags,
  reFetchFolders
}) => {
  const { deleteDocument } = useDocumentsTable();
  const { removeTree, expandedKeys, setExpandedKeys, trees } = useTreeContext();
  const [isOpenModalDuplicateDocument, setIsOpenModalDuplicateDocument] = useState(false);
  const [duplicateOn, setDuplicateOn] = useState({
    teamId: prop('teamId', userTeam),
    offerId: isPublished ? '' : offerId
  });
  const id = prop('elmId', doc);

  const handleDeleteDocument = () => {
    const refetchTags = () => setRefetchTags && setRefetchTags(true);
    if (isNil(prop('treeId', doc))) {
      const nodeKey = compose(
        path([0, 'key']),
        filter(pathEq(['element', 'offerId'], offerId))
      )(trees);

      const updatedKeys = updateKeys(expandedKeys, [nodeKey], 'remove');

      setExpandedKeys(updatedKeys);
      deleteDocument(id, (ok) => {
        if (ok) {
          setDocuments(filter(propNotEq('id', id)));
          refetchTags();
        }
      })();
    } else {
      removeTree(prop('treeId', doc), folder);
      deleteDocument(prop('elmId', doc), (ok) => {
        if (ok) {
          setDocuments(filter(propNotEq('id', prop('elmId', doc))));
          refetchTags();
        }
      })();
    }
    setRefetchWidgets && setRefetchWidgets(true);
  };

  const handleDuplicateDocument = () => {
    setFullscreenLoading(true);
    const documentId = prop('elmId', doc);
    const targetOffer = prop('offerId', duplicateOn);

    DuplicateDocumentAndItsWidgets({ documentId, offerId: targetOffer }, (ok, error) => {
      if (ok && !error) {
        successMessage('document', 'dupliqué');
        if (equals(targetOffer, offerId) && isNotNil(refetch)) {
          !!reFetchFolders && reFetchFolders();
          refetch();
          !!setRefetchWidgets && setRefetchWidgets(true);
        }
      } else {
        errorMessage();
      }
      setFullscreenLoading(false);
    });
  };

  const handleDownloadStructuredHtml = () => {
    setFullscreenLoading(true);
    const documentId = prop('elmId', doc);

    GenerateStructuredHtmlMutation(
      { offerId, documentId },
      (ok, error, html, filename) => {
        setFullscreenLoading(false);

        if (ok && !error) {
          downloadHtmlFile(html, filename);
        } else {
          errorMessage();
        }
      }
    );
  };

  const {
    userIsDocumentEditor = false
  } = documentRights;

  const onChangeTeamDuplicateOn = (teamId) => {
    setDuplicateOn(assoc('teamId', teamId));
    if (notEqual(teamId, prop('teamId', userTeam))) {
      setDuplicateOn(assoc('offerId', null));
    }
  };

  return (
    <Space>
      <Tooltip
        title="Export HTML"
        color="#0197DC"
      >
        <DownloadOutlined onClick={handleDownloadStructuredHtml} className="btn-download-html hover:text-flexibranche-darkgray" />
      </Tooltip>
      {equals(userIsDocumentEditor || userIsAdmin, true) && (
        <>
          {!isPublished && (
            <Popover
              trigger="click"
              title=""
              placement="bottom"
              content={(
                <SelectTags
                  offerId={offerId}
                  documentId={id}
                  setRefetchTags={setRefetchTags}
                />
              )}
            >
              <Tooltip
                title="Lier à un tag"
                color="#0197DC"
              >
                <TagsOutlined className="btn-link-tag hover:text-flexibranche-darkgray" />
              </Tooltip>
            </Popover>
          )}
          {userIsAdmin && (
            <>
              <Modal
                visible={isOpenModalDuplicateDocument}
                onCancel={() => setIsOpenModalDuplicateDocument(false)}
                destroyOnClose
                title="Où souhaitez-vous dupliquer votre document ?"
                onOk={() => {
                  handleDuplicateDocument();
                  setIsOpenModalDuplicateDocument(false);
                }}
              >
                <div className="flex flex-col">
                  <b>Équipe de destination</b>
                  <Select
                    className="mt-5 w-1/2"
                    size="small"
                    onChange={onChangeTeamDuplicateOn}
                    value={prop('teamId', duplicateOn)}
                  >
                    {map((team) => <Select.Option key={prop('id', team)} value={prop('id', team)}>{prop('name', team)}</Select.Option>)(teams)}
                  </Select>
                  <b className="mt-2">Offre de destination</b>
                  <QOffers args={{ filter: { status: 'draft', team: prop('teamId', duplicateOn) } }}>
                    {offers => (
                      <Select
                        className="mt-5 w-1/2"
                        size="small"
                        onChange={(v) => setDuplicateOn(assoc('offerId', v))}
                        value={propOr(null, 'offerId', duplicateOn)}
                        placeholder="Indiquez l'offre de destination"
                      >
                        {map((offer) => <Select.Option key={prop('id', offer)} value={prop('id', offer)}>{prop('offerName', offer)} - {path(['version', 'major'], offer)}.{path(['version', 'minor'], offer)}</Select.Option>)(offers)}
                      </Select>
                    )}
                  </QOffers>
                </div>
              </Modal>
              <Tooltip title="Dupliquer le document" color="#0197DC">
                <CopyOutlined onClick={() => setIsOpenModalDuplicateDocument(true)} className={`${setElementClass(documentName, 'duplicate-document-button')} btn-duplicate-doc`} />
              </Tooltip>
            </>
          )}
          {!isPublished && (
            <Popconfirm
              placement="left"
              title="Êtes-vous sûr de vouloir supprimer ce document ?"
              onConfirm={handleDeleteDocument}
            >
              <Tooltip
                title="Supprimer le document"
                color="#0197DC"
              >
                <DeleteOutlined className={`${setElementClass(documentName, 'delete-document-button')} hover:text-red-300`} />
              </Tooltip>
            </Popconfirm>
          )}
        </>
      )}
    </Space>
  );
};

export default DocumentRights;
